








import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'EmptyMessage',

  props: {
    text: {
      type: String as PropType<string | null>,
      default: 'Ничего не найдено'
    }
  }
})
