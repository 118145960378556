

































import Vue from 'vue'
import {
  mapState,
  mapActions
} from 'vuex'
import { cloneDeep } from '@/utils/functions'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormSelect from '@/components/ui/ValidatedBFormSelect.vue'

export default Vue.extend({
  name: 'SendReportForm',

  components: {
    ValidatedBFormInput,
    ValidatedBFormSelect
  },

  props: {
    selectedDateRange: {
      type: Array,
      default: () => null
    },

    modalShown: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      loading: false,
      recipientEmail: null,
      format: 'pdf',
      formatOptions: [
        {
          value: 'pdf',
          text: 'PDF'
        }
        // {
        //   value: 'excel',
        //   text: 'Excel'
        // }
      ]
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ])
  },

  watch: {
    modalShown (val) {
      if (val) {
        this.recipientEmail = cloneDeep(this.profile.email)
      }
    }
  },

  methods: {
    ...mapActions('orders', [
      'sendReport',
      'createReport'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return
      this.$emit('submit', this.recipientEmail)
    }
  }
})
