























import Vue from 'vue'
import { PAGINATION_LIMIT } from '@/common/constants'

export default Vue.extend({
  name: 'Pagination',

  props: {
    offset: {
      type: Number,
      default: () => 0
    },

    limit: {
      type: Number,
      default: () => PAGINATION_LIMIT
    },

    itemsLength: {
      type: Number,
      default: () => null
    }
  },

  watch: {
    offset (val) {
      this.setRouteQuery('offset', val)
    }
  },

  methods: {
    goPrevPage () {
      if (this.offset <= 0) {
        this.$emit('update:offset', 0)
        return
      }
      this.$emit('update:offset', this.offset - this.limit)
    },

    goNextPage () {
      this.$emit('update:offset', this.offset + this.limit)
    },

    setRouteQuery (paramName: string, paramValue: string) {
      const query = { ...this.$route.query, [paramName]: paramValue }

      this.$router.push({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }
})
