






































import Vue from 'vue'
import {
  mapActions,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import TransactionsHistoryList from './TransactionsHistoryList.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import VueHtml2pdf from 'vue-html2pdf'
import EmptyMessage from '@/components/EmptyMessage.vue'

export default Vue.extend({
  name: 'TransactionsHistory',

  components: {
    Loading,
    DatePicker,
    VueHtml2pdf,
    EmptyMessage,
    TransactionsHistoryList
  },

  data () {
    return {
      reports: [],
      reportsReceived: false,
      fields: [
        {
          key: 'created_at',
          label: 'Дата создания'
        },
        {
          key: 'period',
          label: 'Период'
        },
        {
          key: 'sending_status',
          label: 'Статус'
        },
        {
          key: 'recipient_email',
          label: 'Получатель'
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '1%' }
        }
      ],
      reportRangeDate: null,
      reportRangeDatePrevious: null,
      loading: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      }
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ]),

    today () {
      return this.$moment().format('DD.MM.YYYY')
    }
  },

  created () {
    this.email = this.profile.email
  },

  methods: {
    ...mapActions('orders', [
      'getReports'
    ]),

    async getReportsMethod () {
      try {
        this.loading = true
        const res = await this.getReports(this.profile.profile_data.id)
        this.reportsReceived = true
        this.reports = res
      } catch (error) {
      } finally {
        this.loading = false
        this.reportRangeDatePrevious = this.reportRangeDate
      }
    },

    disabledAfterAndToday (date) {
      const today = new Date()
      return date > new Date(today.getTime())
    }
  }
})
