






































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { PAGINATION_LIMIT } from '@/common/constants'

export default Vue.extend({
  name: 'PromocodesList',

  components: {
    Loading,
    Pagination
  },

  props: {
    tableSmall: {
      type: Boolean,
      default: () => false
    },

    items: {
      type: Array,
      default: () => []
    },

    fields: {
      type: Array,
      default: () => []
    },

    offset: {
      type: Number,
      default: () => 0
    },

    limit: {
      type: Number,
      default: () => PAGINATION_LIMIT
    }
  },

  methods: {
    getStatus (val: string) {
      switch (val) {
        case 'confirmed':
          return 'Выполнено'
        case 'canceled':
          return 'Отменено'
        default:
          return ''
      }
    },

    goPrevPage () {
      if (this.offset <= 0) {
        this.$emit('update:offset', 0)
        return
      }
      this.$emit('update:offset', this.offset - this.limit)
    },

    goNextPage () {
      this.$emit('update:offset', this.offset + this.limit)
    }
  }
})
