
































import Vue, { PropType } from 'vue'
import { getUniqueId } from '@/utils/functions'

export default Vue.extend({
  name: 'ValidatedBFormInput',

  props: {
    label: {
      type: String as PropType<string | null>,
      default: null
    },

    placeholder: {
      type: String as PropType<string | null>,
      default: null
    },

    labelColsSm: {
      type: Number as PropType<number | null>,
      default: null
    },

    step: {
      type: Number as PropType<number | null>,
      default: () => 1
    },

    rules: {
      type: [String, Object] as PropType<string | object | null>,
      default: null
    },

    id: {
      type: String as PropType<string>,
      default: () => 'ValidatedFloatedBFormInput-' + getUniqueId()
    },

    type: {
      type: String as PropType<string>,
      default: 'text'
    },

    plaintext: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },

    value: {
      type: [String, Number] as PropType<string | number | null>,
      default: null
    }
  },

  computed: {
    vModel: {
      get () {
        return this.value
      },

      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },

  methods: {
    validate () {
      return this.$refs.validationProvider.validate()
    }
  }
})
