






























































































































































































import Vue from 'vue'
import {
  mapActions,
  mapMutations,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import TransactionsList from './TransactionsList.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
// import { handleErrors } from '@/utils/functions'
import EmptyMessage from '@/components/EmptyMessage.vue'
import windowWidth from '@/mixins/windowWidth'

export default Vue.extend({
  name: 'Transactions',

  components: {
    Loading,
    DatePicker,
    EmptyMessage,
    TransactionsList
  },

  mixins: [
    windowWidth
  ],

  data () {
    return {
      isModalShown: false,
      report: null,
      requisites: null,
      transactionsGenerated: false,
      transactions: [],
      transactionsPaymentData: {},
      email: null,
      fields: [
        {
          key: 'number',
          label: 'Номер заказа'
        },
        {
          key: 'placed_at',
          label: 'Дата поступления заказа'
        },
        {
          key: 'ended_at',
          label: 'Дата окончания доставки'
        },
        {
          key: 'price',
          label: 'Стоимость заказа, руб'
        },
        {
          key: 'partner_promo',
          label: 'Промо от партнера за заказ'
        },
        {
          key: 'copyright_holder_promo',
          label: 'Промо от правообладателя за заказ'
        },
        {
          key: 'delivery_price',
          label: 'Стоимость доставки, руб'
        },
        {
          key: 'delivery_paid_by_user',
          label: 'Оплачено Пользователем за доставку'
        },
        {
          key: 'delivery_paid_by_copyright_holder',
          label: 'Оплачено правообладателем за доставку'
        },
        {
          key: 'delivery_pay_for_partner',
          label: 'К оплате партнером за доставку'
        },
        {
          key: 'payment_commission',
          label: 'Стоимость Услуг в проведении Операции, %'
        },
        {
          key: 'refound',
          label: 'Возвраты/Отмены'
        },
        {
          key: 'to_pay',
          label: 'К перечислению'
        },
        {
          key: 'transfered',
          label: 'Перечислено (автопереводы)'
        },
        {
          key: 'debt',
          label: 'Задолженность правообладателя/(-партнера)'
        },
        {
          key: 'status',
          label: 'Статус'
        }
      ],
      // fieldsForPrint: [
      //   {
      //     key: 'number',
      //     label: 'Номер заказа'
      //   },
      //   {
      //     key: 'placed_at',
      //     label: 'Дата поступления заказа'
      //   },
      //   {
      //     key: 'ended_at',
      //     label: 'Дата окончания доставки'
      //   },
      //   {
      //     key: 'price',
      //     label: 'Стоимость заказа, руб'
      //   },
      //   {
      //     key: 'partner_promo',
      //     label: 'Промо от партнера за заказ'
      //   },
      //   {
      //     key: 'copyright_holder_promo',
      //     label: 'Промо от право- обладателя за заказ'
      //   },
      //   {
      //     key: 'delivery_price',
      //     label: 'Стоимость доставки, руб'
      //   },
      //   {
      //     key: 'delivery_paid_by_user',
      //     label: 'Оплачено Пользова- телем за доставку'
      //   },
      //   {
      //     key: 'delivery_paid_by_copyright_holder',
      //     label: 'Оплачено право- обладателем за доставку'
      //   },
      //   {
      //     key: 'delivery_pay_for_partner',
      //     label: 'К оплате партнером за доставку'
      //   },
      //   {
      //     key: 'payment_commission',
      //     label: 'Стоимость Услуг в проведении Операции, %'
      //   },
      //   {
      //     key: 'refound',
      //     label: 'Возвраты/Отмены'
      //   },
      //   {
      //     key: 'to_pay',
      //     label: 'К перечи- слению'
      //   },
      //   {
      //     key: 'transfered',
      //     label: 'Перечислено (автопере- воды)'
      //   },
      //   {
      //     key: 'debt',
      //     label: 'Задолжен- ность право- обладателя / (-партнера)'
      //   },
      //   {
      //     key: 'status',
      //     label: 'Статус'
      //   }
      // ],
      reportRangeDate: null,
      reportRangeDatePrevious: null,
      loading: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      }
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ]),

    today () {
      return this.$moment().format('DD.MM.YYYY')
    }
  },

  watch: {
    reportRangeDate (val) {
      this.setTransactionsDateRange(val)
    }
  },

  created () {
    this.email = this.profile.email
    // this.getRequisites()
  },

  methods: {
    ...mapActions('orders', [
      'getOrderTransactions',
      'sendReportToEmail',
      'createReport'
    ]),

    ...mapActions('orders', [
      'createOrdersReport'
    ]),

    ...mapMutations('orders', [
      'setTransactionsDateRange'
    ]),

    // async getRequisites () {
    //   try {
    //     this.loading = true
    //     const res = await this.getProfileRequisites(this.profile.profile_data.id)
    //     this.requisites = res
    //   } catch (error) {
    //   } finally {
    //     this.loading = false
    //   }
    // },

    // onProgress (val) {
    //   if (val === 100) this.loading = false
    // },

    async getTransactions () {
      // Закомменировал код, потому что пока делаем другую логику https://bsldev.atlassian.net/browse/MAR-4231
      // try {
      //   this.loading = true
      //   this.transactions = []
      //   this.transactionsPaymentData = {}
      //   const res = await this.getOrderTransactions({
      //     from: this.$moment(this.reportRangeDate[0], 'DD.MM.YYYY').utc(true).unix(),
      //     to: this.$moment(this.reportRangeDate[1], 'DD.MM.YYYY').utc(true).unix(),
      //     profileId: this.profile.profile_data.id,
      //     limit: 25,
      //     offset: 0
      //   })
      //   this.transactions = res.order_transactions
      //   this.transactions.push({
      //     number: 'Итого',
      //     ...res.total_score
      //   })
      //   this.transactionsPaymentData = res.payment_data
      // } catch (error) {
      //   handleErrors(error)
      // } finally {
      //   this.loading = false
      //   this.transactionsGenerated = true
      //   this.reportRangeDatePrevious = this.reportRangeDate
      // }
      // this.loading = true
      // this.$refs.html2Pdf.generatePdf()
      this.loading = true
      const [fromDate, toDate] = this.reportRangeDate
      this.report = await this.createOrdersReport({
        from_date: fromDate.split('.').reverse().join('-'),
        to_date: toDate.split('.').reverse().join('-'),
        bp_login: this.profile.username
      })
      this.loading = false
      this.isModalShown = true
    },

    disabledAfterAndToday (date) {
      const today = new Date()
      return date > new Date(today.getTime() - 1 * 24 * 3600 * 1000)
    },

    handleClickDownloadReport () {
      window.open(this.report, '_blank')
      this.isModalShown = false
      this.report = null
    }
  }
})
