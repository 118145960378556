









































import Vue from 'vue'
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
import Transactions from './Transactions.vue'
import TransactionsHistory from './TransactionsHistory.vue'
import SendReportForm from './SendReportForm.vue'
import { handleErrors } from '@/utils/functions'
import Loading from '@/components/Loading/index.vue'

export default Vue.extend({
  name: 'ProfileReport',

  components: {
    Transactions,
    TransactionsHistory,
    SendReportForm,
    Loading
  },

  data () {
    return {
      loading: false,
      modalShown: false
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ]),

    ...mapState('orders', [
      'transactionsDateRange',
      'sendingReadyReport'
    ])
  },

  methods: {
    ...mapActions('orders', [
      'sendReportToEmail',
      'createReport'
    ]),

    ...mapMutations('orders', [
      'setSendingReadyReport'
    ]),

    async handleSubmitReport (recipientEmail: string) {
      if (!this.sendingReadyReport) {
        const createdReport = await this.createReportMethod()
        this.setSendingReadyReport(createdReport)
      }
      await this.sendReportToEmailMethod(recipientEmail)
    },

    async createReportMethod (dateRange) {
      if (!dateRange) dateRange = this.transactionsDateRange
      try {
        this.loading = true
        const res = await this.createReport({
          from: this.$moment(dateRange[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
          to: this.$moment(dateRange[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
          businessProfileId: this.profile.profile_data.id
        })
        return res
      } catch (error) {
        handleErrors(error)
        // this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },

    async sendReportToEmailMethod (recipientEmail: string) {
      const dateRange = [this.sendingReadyReport.from_date, this.sendingReadyReport.to_date]

      try {
        this.loading = true
        await this.sendReportToEmail({
          subject: `Отчёт Правообладателя за период ${dateRange[0]}-${dateRange[1]}`,
          body: 'Отчёт доступен в прикрепленном файле',
          recipientEmail,
          reportId: this.sendingReadyReport.id
        })
        this.$bvModal.hide('modal-send-report')
        setTimeout(() => {
          this.$toast.success('Отчёт отправлен на почту')
        }, 100)
      } catch (error) {
        handleErrors(error)
      } finally {
        this.loading = false
      }
    },

    async handleDownloadReport (dateRange) {
      this.loading = true
      // this.$refs.html2Pdf.generatePdf()
      const result = await this.createReportMethod(dateRange)
      window.open(result.document_url, '_blank')
    },

    onHiddenModal () {
      this.setSendingReadyReport(null)
    }
  }
})
