




























import Vue, { PropType } from 'vue'
import { getUniqueId } from '@/utils/functions'

export default Vue.extend({
  name: 'ValidatedBFormSelect',

  props: {
    label: {
      type: String as PropType<string | null>,
      default: null
    },

    labelColsSm: {
      type: Number as PropType<number | null>,
      default: null
    },

    rules: {
      type: String as PropType<string | null>,
      default: null
    },

    options: {
      type: Array as PropType<object>,
      default: null
    },

    id: {
      type: String as PropType<string>,
      default: () => 'ValidatedFloatedBFormInput-' + getUniqueId()
    },

    type: {
      type: String as PropType<string>,
      default: 'text'
    },

    value: {
      type: [String, Number] as PropType<string | number | null>,
      default: null
    },

    valueField: {
      type: String as PropType<string>,
      default: () => 'value'
    },

    textField: {
      type: String as PropType<string>,
      default: () => 'text'
    }
  },

  computed: {
    vModel: {
      get () {
        return this.value
      },

      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  }
})
